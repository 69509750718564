import { combineReducers } from "redux";
import {
  articleReducer,
  ArticleCollectionInterface
} from "reducers/articleReducer";
import { configReducer, ConfigInterface } from "reducers/configReducer";
import { variantReducer, VariantState } from "reducers/variantReducer";
import { editionReducer, EditionStateInterface } from "reducers/editionReducer";

export interface StateInterface {
  config: ConfigInterface;
  articles: ArticleCollectionInterface;
  variants: VariantState;
  editions: EditionStateInterface;
}

export const rootReducer = combineReducers({
  articles: articleReducer,
  config: configReducer,
  variants: variantReducer,
  editions: editionReducer
});
