import { DrawerProps } from "antd-mobile/lib/drawer/PropsType";
import { getQueryParams } from "libs/utils";

export interface DrawerPositionVariant {
  id: "drawerPosition";
  options: DrawerProps["position"][];
  selected: DrawerProps["position"];
  defaultSelected: DrawerProps["position"];
  title: string;
}

const queryParams = getQueryParams();

export const drawerPosition: DrawerPositionVariant = {
  id: "drawerPosition",
  options: ["left", "right"],
  selected: queryParams["drawerPosition"]
    ? (queryParams["drawerPosition"] as DrawerProps["position"])
    : "right",
  defaultSelected: "right",
  title: "Drawer position"
};
