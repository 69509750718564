import { DrawerPositionVariant, drawerPosition } from "variants/drawerPosition";
import { premiumCopyVariant } from "variants/premiumLabel";
import { menuTypes, MenuTypesInterface } from "variants/menuTypes";
import {
  UpdateVariantActionType,
  VariantActions
} from "actions/variantActions";

export interface VariantInterface {
  id: string;
  title: string;
  selected: string | number | DrawerPositionVariant["selected"];
  defaultSelected: string | number | DrawerPositionVariant["selected"];
  options: (DrawerPositionVariant["selected"] | number | string)[];
}

export interface VariantState {
  [id: string]: VariantInterface;
  [drawerPosition.id]: DrawerPositionVariant;
  [premiumCopyVariant.id]: VariantInterface;
  [menuTypes.id]: MenuTypesInterface;
}

const initialState: VariantState = {
  drawerPosition: drawerPosition,
  premiumLabel: premiumCopyVariant,
  menuTypes: menuTypes
};

export function variantReducer(
  state: VariantState = initialState,
  action: UpdateVariantActionType
): VariantState {
  switch (action.type) {
    case VariantActions.UPDATE_VARIANT:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          ...action.payload
        }
      };
    case VariantActions.RESET_VARIANTS:
      return Object.keys(state).reduce(
        (acc, key): VariantState => ({
          ...acc,
          [key]: {
            ...acc[key],
            selected: acc[key].defaultSelected
          }
        }),
        { ...state }
      );
    default:
      return state;
  }
}
