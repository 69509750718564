import {
  ConfigActionInterfaces,
  CONFIG_ACTION_TYPES
} from "actions/ConfigActions";

export enum Areas {
  EDITION = "EDITION",
  LIVE = "EDITION/LIVE_EDITION",
  SEARCH = "SEARCH",
  ACTIVITY = "ACTIVITY",
  DEBUG = "DEBUG",
  LOADING = "LOADING",
  SETTINGS = "SETTINGS"
}

export type AreasTypes = keyof typeof Areas;

export enum ViewModes {
  INDEX = "INDEX",
  ARTICLE = "ARTICLE",
  ARTICLE_SINGLE = "ARTICLE_SINGLE",
  NEWS_STAND = "NEWS_STAND",
  NAVIGATION = "NAVIGATION",
  BLANK = "BLANK"
}
export type ViewModesType = keyof typeof ViewModes;

export enum Themes {
  TELEGRAPH = "TELEGRAPH",
  GREY = "GREY"
}
export type ThemeTypes = keyof typeof Themes;

export interface ConfigInterface {
  activeArticle: string | undefined;
  activeSection: string | undefined;
  activeEdition: string;
  area: AreasTypes;
  isDrawerOpen: boolean;
  isOffline: boolean;
  checkingOfflineStatus: boolean;
  theme: ThemeTypes;
  viewMode: ViewModesType;
}

export const configInitialState: ConfigInterface = {
  activeArticle: undefined,
  activeSection: "news-lv0",
  activeEdition: "LIVE_EDITION",
  area: Areas.EDITION,
  checkingOfflineStatus: true,
  isDrawerOpen: false,
  isOffline: false,
  theme: "GREY",
  viewMode: ViewModes.INDEX
};

export function configReducer(
  state = configInitialState,
  action: ConfigActionInterfaces
): ConfigInterface {
  switch (action.type) {
    case CONFIG_ACTION_TYPES.IS_OFFLINE:
      return {
        ...state,
        isOffline: action.payload,
        checkingOfflineStatus: false
      };

    case CONFIG_ACTION_TYPES.VIEW_MODE:
      return {
        ...state,
        viewMode: action.payload
      };

    case CONFIG_ACTION_TYPES.SET_AREA:
      return {
        ...state,
        area: action.payload
      };

    case CONFIG_ACTION_TYPES.SET_ACTIVE_ARTICLE:
      return {
        ...state,
        activeArticle: action.payload
      };

    case CONFIG_ACTION_TYPES.SET_ACTIVE_SECTION:
      return {
        ...state,
        activeSection: action.payload
      };

    case CONFIG_ACTION_TYPES.SET_ACTIVE_EDITION:
      return {
        ...state,
        activeEdition: action.payload,
        activeSection: undefined
      };

    case CONFIG_ACTION_TYPES.TOGGLE_SIDE_DRAWER:
      return { ...state, isDrawerOpen: !state.isDrawerOpen };

    case CONFIG_ACTION_TYPES.SET_THEME:
      return {
        ...state,
        theme: action.payload
      };

    default:
      return state;
  }
}
