import memoizeOne from "memoize-one";
import { ArticleType } from "reducers/articleReducer";

function getArticles(
  articleIds: string[],
  articles: { [id: string]: ArticleType }
): ArticleType[] {
  return articleIds.reduce((acc: ArticleType[], id): ArticleType[] => {
    if (articles[id]) {
      acc.push(articles[id]);
    }
    return acc;
  }, []);
}

/**
 * Cache section article selection
 */
export const selectArticles = memoizeOne(
  getArticles,
  ([ids, articles], [oldIds, oldArticles]): boolean => {
    if (ids.join() !== oldIds.join()) {
      return false;
    }
    if (Object.keys(articles).join() !== Object.keys(oldArticles).join()) {
      return false;
    }

    return true;
  }
);
