import {
  EditionActionsInterfaces,
  EditionActionsTypes,
  EditionInterface,
  PackageInterface
} from "actions/EditionActions";
import { editionState } from "data/edition_state";

export interface EditionStateInterface {
  readonly [id: string]: EditionInterface;
}

export function editionReducer(
  state = editionState as EditionStateInterface,
  action: EditionActionsInterfaces
): EditionStateInterface {
  switch (action.type) {
    case EditionActionsTypes.FETCHING_SECTION_CONTENT: {
      const { payload } = action;
      return {
        ...state,
        [payload.editionId]: {
          ...state[payload.editionId],
          sections: {
            ...state[payload.editionId].sections,
            [payload.sectionId]: {
              ...state[payload.editionId].sections[payload.sectionId],
              fetching: true
            }
          }
        }
      };
    }

    case EditionActionsTypes.RECEIVED_SECTION_CONTENT: {
      const { payload } = action;
      return {
        ...state,
        [payload.editionId]: {
          ...state[payload.editionId],
          sections: {
            ...state[payload.editionId].sections,
            [payload.sectionId]: {
              ...state[payload.editionId].sections[payload.sectionId],
              fetching: false,
              loaded: true,
              articles: payload.articleIds || []
            }
          }
        }
      };
    }

    case EditionActionsTypes.ADD_PACKAGES: {
      const {
        payload: { editionId, sectionId, packages }
      } = action;

      return {
        ...state,
        [editionId]: {
          ...state[editionId],
          packages: {
            ...state[editionId].packages,
            ...packages.reduce(
              (acc, pack): { [id: string]: PackageInterface } => ({
                ...acc,
                [pack.id]: pack
              }),
              {}
            )
          },
          sections: {
            ...state[editionId].sections,
            [sectionId]: {
              ...state[editionId].sections[sectionId],
              packages: packages.map((pak): string => pak.id)
            }
          }
        }
      };
    }

    default:
      return state;
  }
}
