import React, { ErrorInfo } from "react";

export class ErrorBoundary extends React.Component<
  { children: JSX.Element },
  { hasError: boolean }
> {
  public constructor(props: { children: JSX.Element }) {
    super(props);
    this.state = { hasError: false };
  }

  public componentDidCatch(error: Error, info: ErrorInfo): void {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    console.log(error, info);
  }

  public render(): JSX.Element {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }
    return this.props.children;
  }
}
