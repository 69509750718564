import { createStore, Store, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
//@ts-ignore
import { createLogger } from "redux-logger";
import { rootReducer } from "reducers/rootReducer";

const logger = createLogger({
  collapsed: true,
  diff: true
});

function configureStore(): Store {
  return createStore(rootReducer, applyMiddleware(thunkMiddleware, logger));
}

export const store = configureStore();
