export interface SectionResponseInterface {
  url: string;
  id: string;
  title: string;
  packages: PackagesItemInterface[];
  articles: KeyedArticleResponseInterface;
}

export interface PackagesItemInterface {
  id: string;
  title: null | string;
  url: null | string;
  articles: string[];
}

export interface KeyedArticleResponseInterface {
  [id: string]: ArticleResponseInterface;
}

export interface ArticleResponseInterface {
  id: string;
  title: string;
  url: string;
  ampUrl: string;
  premium: boolean;
  preview: boolean;
  snippet: string | null;
  datePublished: string;
  contentType: "story" | "video" | "live" | null;
  leadAsset: LeadAsset;
  authors: AuthorsItem[];
  body: BodyItem[];
}
interface LeadAsset {
  type: string;
  url: string;
  alt: string;
}
interface AuthorsItem {
  image: null;
  name: string | null;
  title: null;
}
export interface BodyItem {
  type: BodyTypes;
  text?: string;
  src?: string;
  alt?: string;
}

export type BodyTypes = "text" | "image" | "tweet" | "video";

enum CONTENT_TYPES {
  article = "article",
  section = "section",
  search = "search"
}

type ContentTypes = keyof typeof CONTENT_TYPES;

const PROXY_KEY = "PROTO_DEV";
const PROXY_BASE_URL = `https://tmg-proxy.am-telegraph.now.sh/`;

async function fetchProxyContent(
  type: ContentTypes,
  query: string
): Promise<{} | []> {
  const url = new URL(PROXY_BASE_URL);
  url.searchParams.append("api", "1");
  url.searchParams.append("route", type);
  url.searchParams.append("key", PROXY_KEY);
  url.searchParams.append("id", query);

  const res = await fetch(url.href, {
    method: "GET",
    mode: "cors"
  });

  if (res.status !== 200) {
    throw new Error(`Error from search API. ${res.status}`);
  }

  return res.json();
}

export async function getArticle(
  query: string,
  isSpoofed?: boolean
): Promise<ArticleResponseInterface> {
  return (await fetchProxyContent(
    "article",
    query
  )) as ArticleResponseInterface;
}

export async function getSection(
  query: string,
  isSpoofed?: boolean
): Promise<SectionResponseInterface> {
  return (await fetchProxyContent(
    "section",
    query
  )) as SectionResponseInterface;
}

export interface SearchResultsInterface {
  suggestedSections: string[];
  results: KeyedArticleResponseInterface;
}

export async function getSearchResults(
  query: string
): Promise<SearchResultsInterface> {
  const resultJSON = await fetchProxyContent("search", query);
  try {
    return resultJSON as SearchResultsInterface;
  } catch (err) {
    throw new Error(`Problem parsing search results. ${err && err.message}`);
  }
}
