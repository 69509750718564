import { ArticleActionsInterface, ActionTypeKey } from "actions/ArticleActions";
import { ArticleResponseInterface } from "services/content/contentProxy";

export interface ArticleType extends ArticleResponseInterface {
  isFetching: boolean;
  hasRead: boolean;
}

export interface ArticleCollectionInterface {
  [id: string]: ArticleType;
}

export function articleReducer(
  state: ArticleCollectionInterface = {},
  action: ArticleActionsInterface
): ArticleCollectionInterface {
  switch (action.type) {
    case ActionTypeKey.ADD_STORY: {
      const { id } = action.payload;
      return {
        ...state,
        [id]: {
          ...state[id],
          ...action.payload
        }
      };
    }

    case ActionTypeKey.IMPORT_SECTION_ARTICLES: {
      const stateClone = { ...state };
      // Merge articles into state
      Object.entries(action.payload).forEach(([id, article]): void => {
        let currentArticle = stateClone[id];
        if (!currentArticle) {
          stateClone[id] = { ...article };
        }
      });
      return stateClone;
    }

    case ActionTypeKey.FETCH_ARTICLE: {
      const { articleId: id, data } = action.payload;
      const article = {
        ...state[id],
        ...data,
        isPartial: false,
        isFetching: false
      };

      return {
        ...state,
        [id]: article
      };
    }

    default:
      return state;
  }
}
