import { store } from "store/storeConfig";
import { StateInterface } from "reducers/rootReducer";
import { setQueryParams } from "libs/utils";
import { Unsubscribe } from "redux";

class StoreState {
  public constructor() {
    const initialState: StateInterface = store.getState();
    if ("variants" in initialState === false) {
      return;
    }

    this.variantValues = this.getVariantValues(initialState.variants);
    this.subId = store.subscribe(this.updateLocationOnVariantChange);
  }

  private subId: undefined | Unsubscribe = undefined;
  private variantValues: undefined | string = undefined;

  private getVariantValues = (variants: StateInterface["variants"]): string =>
    Object.values(variants).reduce(
      (acc: string, v): string => acc + v.selected,
      ""
    );

  private updateLocationOnVariantChange = (): void => {
    const { variants }: StateInterface = store.getState();
    const variantValues = this.getVariantValues(variants);

    if (variantValues === this.variantValues) {
      return;
    }

    this.variantValues = variantValues;

    let params: { [id: string]: string | number | undefined } = {};
    for (const k in variants) {
      params[k] = variants[k].selected;
    }

    setQueryParams(params);
  };
}

const storeState = new StoreState();

export default storeState;
