import { KeyboardEventHandler } from "react";

/** Hash a string into 32bit number  */
export function hashCode(str: string): number {
  let hash = 0;
  if (str.length == 0) {
    return hash;
  }
  for (let i = 0; i < str.length; i++) {
    let char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }
  return hash;
}

/** Generate simple hash ID from string */
export function hashId(str: string, prefix: string = "id_"): string {
  const hash = Math.abs(hashCode(str)).toString();
  return `${prefix}${hash}`;
}

export function checkIfOnline(targetUrl?: string): Promise<boolean> {
  const TIMEOUT = 2000;
  const url =
    targetUrl ||
    "https://lab-api-preprod.api-platforms-preprod.telegraph.co.uk/top-stories";
  const abortController = new AbortController();
  const signal = abortController.signal;
  let timeoutId: undefined | number = undefined;

  return new Promise((resolve): void => {
    fetch(url, { signal })
      .then((response): void => {
        clearTimeout(timeoutId);
        if (response.ok) {
          return resolve(false);
        } else {
          resolve(true);
        }
      })
      .catch((): void => {
        clearTimeout(timeoutId);
        resolve(true);
      });

    timeoutId = window.setTimeout((): void => {
      abortController.abort();
    }, TIMEOUT);
  });
}

export function getQueryParams(): { [k: string]: string } {
  // return window.location.search
  //   .replace(/^\?/, "")
  //   .split("&")
  //   .reduce((acc: { [k: string]: string }, param: string): {
  //     [k: string]: string;
  //   } => {
  //     const [key, val] = param.split("=");
  //     if (key.length > 0) {
  //       acc[key] = JSON.parse(decodeURIComponent(val));
  //     }
  //     return acc;
  //   }, {});
  return {};
}

// Update query param in URL
export function setQueryParams(params: {}, replace: boolean = true): void {
  const paramString = Object.entries(params)
    .map(([k, v]): string => `${k}=${JSON.stringify(v)}`)
    .join("&");
  const newUrl = paramString.length > 0 ? "?" + paramString : "/";

  replace
    ? window.history.replaceState({}, "", newUrl)
    : window.history.pushState({}, "", newUrl);
}

/**
 *  Clean text by using correct punctuation
 *
 * Additional rules taken from https://github.com/bevacqua/beautify-text
 */
export function cleanPunctuation(text: string): string {
  return text
    .replace(/&nbsp;/g, " ")
    .replace(/>"/g, ">“")
    .replace(': "', ": “")
    .replace('."', ".”")
    .replace(/\+-/gm, "±")
    .replace(/\.{2,}/gm, "…") // .., ..., ....... -> …
    .replace(/([?!])…/gm, "$1..") // ?..... & !..... -> ?.. & !..
    .replace(/([?!]){4,}/gm, "$1$1$1")
    .replace(/,{2,}/gm, ",")
    .replace(/(^|[^-])---([^-]|$)/gm, "$1\u2014$2") // em-dash
    .replace(/(^|\s)--(\s|$)/gm, "$1\u2013$2") // en-dash
    .replace(/(^|[^-\s])--([^-\s]|$)/gm, "$1\u2013$2"); // en-dash
}

export function handleEnterKeyPress(fn: Function): KeyboardEventHandler {
  return function(event): void {
    const { key } = event;
    if (key === "Enter") {
      fn();
    }
  };
}
