import { getQueryParams } from "libs/utils";

export interface PremiumCopyVariantInterface {
  id: "premiumLabel";
  title: string;
  defaultSelected: string;
  selected: string;
  options: string[];
  data?: {
    [id: string]: string | number | {};
  };
}

export enum PremiumOptions {
  PREMIUM = "Premium",
  AWESOME = "Awesome",
  EXCLUSIVE = "Exclusive",
  SUBSCRIBER = "Subscriber"
}

export const DEFAULT_SELECTED = "PREMIUM";

type PremiumOptionsType = keyof typeof PremiumOptions;

const queryParams = getQueryParams();

export const premiumCopyVariant: PremiumCopyVariantInterface = {
  id: "premiumLabel",
  title: "Alternative premium copy",
  options: Object.keys(PremiumOptions),
  defaultSelected: DEFAULT_SELECTED,
  selected: queryParams["premiumLabel"]
    ? queryParams["premiumLabel"]
    : DEFAULT_SELECTED,
  data: Object.keys(PremiumOptions).reduce((acc: {}, k): {} => {
    return {
      ...acc,
      [k]: { PREMIUM: PremiumOptions[k as PremiumOptionsType] }
    };
  }, {})
};
