import React, { useEffect } from "react";
import FastClick from "fastclick";
import dlv from "dlv";
import App from "./App";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { useRoute, useLocation } from "wouter";
import { ErrorBoundary } from "libs/ErrorBoundary";
import { store } from "store/storeConfig";
import { Areas, AreasTypes } from "reducers/configReducer";
import "services/storeState";
import "antd-mobile/dist/antd-mobile.css";

// @ts-ignore
FastClick.attach(document.body);

interface UrlStateInt {
  area: null | AreasTypes;
  searchQuery: null | string;
  activeArticle: null | string;
  activeSection: null | string;
  activeEdition: null | string;
  settingId: null | string;
  activityId: null | string;
  isSearchOpen: boolean;
  isSingleArticle: boolean;
  viewMode: null | string;
}

function Router(): JSX.Element | null {
  const [, setLocation] = useLocation();
  const route = useRoute("/:area?/:param1?/:param2?/:param3?");
  const areaParam = dlv(route, "1.area");

  let urlState: UrlStateInt = {
    area: areaParam,
    activeArticle: null,
    activeSection: null,
    activeEdition: null,
    settingId: null,
    activityId: null,
    searchQuery: null,
    isSearchOpen: false,
    isSingleArticle: false,
    viewMode: null
  };

  let invalidLocation = false;

  // Main routing switcher
  switch (areaParam) {
    case Areas.EDITION:
      const activeEdition = dlv(route, "1.param1");
      const state = store.getState();

      // Ensdure valid edition ID
      if (
        activeEdition &&
        !Object.keys(state.editions).includes(activeEdition)
      ) {
        console.warn("Invaid Edition ID");
        invalidLocation = true;
        break;
      }

      const isSearchOpen = dlv(route, "1.param2") === "search";
      const isSingleArticle = dlv(route, "1.param2") === "article";
      const activeSection = !isSearchOpen && dlv(route, "1.param2");
      const sections = dlv(state.editions, `${activeEdition}.sections`, []);

      console.log(Object.keys(sections).includes(activeSection), activeSection);

      // Ensdure valid section ID
      if (
        activeEdition &&
        !isSearchOpen &&
        !isSingleArticle &&
        !Object.keys(sections).includes(activeSection)
      ) {
        console.warn("Invaid Section ID");
        invalidLocation = true;
        break;
      }

      urlState = {
        ...urlState,
        activeEdition,
        activeSection,
        activeArticle: !isSearchOpen && dlv(route, "1.param3"),
        isSearchOpen,
        isSingleArticle,
        searchQuery: isSearchOpen && dlv(route, "1.param2")
      };
      break;

    case Areas.SETTINGS:
      urlState = {
        ...urlState,
        settingId: dlv(route, "1.param1")
      };
      break;

    case Areas.ACTIVITY:
      urlState = {
        ...urlState,
        activityId: dlv(route, "1.param1")
      };
      break;

    default:
      invalidLocation = true;
  }

  useEffect((): void => {
    if (invalidLocation) {
      console.warn("Re-routing");
      setLocation("/EDITION/LIVE_EDITION/news-lv0/");
    }
  });

  // @ts-ignore - React-redux connect wrapper issue
  // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/16990#issue-233875315
  return <App {...urlState} />;
}

render(
  <Provider store={store}>
    <ErrorBoundary>
      <Router />
    </ErrorBoundary>
  </Provider>,
  document.querySelector("#app")
);
