import { getQueryParams } from "libs/utils";

export enum MENU_CHOICES {
  GRID = "GRID",
  CAROUSEL = "CAROUSEL",
  COMPRESSED_CAROUSEL = "COMPRESSED_CAROUSEL",
  ONE_COL = "ONE_COL",
  THREE_COL = "THREE_COL",
  SINGLE_TOUCH = "SINGLE_TOUCH"
}

export type MenuChoicesTypes = keyof typeof MENU_CHOICES;

export interface MenuTypesInterface {
  id: "menuTypes";
  options: MenuChoicesTypes[];
  selected: MenuChoicesTypes;
  defaultSelected: MenuChoicesTypes;
  title: string;
}

const queryParams = getQueryParams();

export const menuTypes: MenuTypesInterface = {
  id: "menuTypes",
  options: [
    MENU_CHOICES.GRID,
    MENU_CHOICES.CAROUSEL,
    MENU_CHOICES.COMPRESSED_CAROUSEL,
    MENU_CHOICES.ONE_COL,
    MENU_CHOICES.THREE_COL,
    MENU_CHOICES.SINGLE_TOUCH
  ],
  selected: queryParams["menuType"]
    ? (queryParams["menuType"] as MenuChoicesTypes)
    : MENU_CHOICES.GRID,
  defaultSelected: MENU_CHOICES.GRID,
  title: "Menu type"
};
