import { store } from "store/storeConfig";

const localeEn = {
  DRAWER_EDIT: "Edit",
  DRAWER_MENU: "Menu",
  DRAWER_SETTINGS: "Settings",
  PREMIUM: "Premium",
  COMMON_SUBSCRIBE: "Subscribe"
};

export let locale = { ...localeEn };

// VARIANT: Listen for changes to locale and update copy
let previousLocale = "";

function handleVariantStateChange(): void {
  const state = store.getState();
  const { selected } = state.variants["premiumLabel"];
  if (previousLocale === selected) {
    return;
  }

  previousLocale = selected;
  const localeVariant = state.variants["premiumLabel"].data[selected];
  locale = {
    ...localeEn,
    ...localeVariant
  };
}

store.subscribe(handleVariantStateChange);

export default locale;
